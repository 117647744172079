.psychologistlistpage-table,
.psychologistlistpage-table th,
.psychologistlistpage-table td {
  border: solid 1px black;
  border-collapse: collapse;
  padding: 0.125rem;
}

.psychologistlistpage-table input {
  background: none;
  border: none;
}

.psychologistlistpage-table tr:nth-child(odd) {
  background-color: lightgray;
}
