.locationlistpage-table {
  border-collapse: collapse;
}

.locationlistpage-table th,
.locationlistpage-table td {
  border: solid 1px black;
  text-align: center;
}

.locationlistpage-table tr:nth-child(odd) {
  background-color: lightgrey;
}

.locationlistpage-table input {
  border: none;
  background: none;
  margin: 0;
}

.locationlistpage-table .add-location-button {
  width: 100%;
}
