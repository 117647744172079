.simple-table tr {
  border-bottom: 1px solid #ddd;
}
.simple-table td {
  border-right: 1px solid #ddd;
  border-left: 1px solid #ddd;
  white-space: nowrap;
  /*padding: 5px;*/
}
.simple-table input {
  width: 100%;
  border: none;
  background-color: transparent;
}
.simple-table th {
  white-space: nowrap;
  border: 2px solid #222;
  padding: 5px;
}
.simple-table {
  border-collapse: collapse;
  width: 100%;
}

.simple-table tbody tr:nth-child(odd) {
  background-color: #eee;
}

.simple-table .delete-button {
  border-radius: 5px;
  margin-left: 5px;
  border: solid 1px red;
  background: none;
  color: red;
  cursor: pointer;
}

.simple-table .delete-button:hover {
  box-shadow: 0px 2px 5px rgba(255, 0, 0, 0.5);
}

.simple-table .add-button {
  border: solid 1px #222;
  border-radius: 5px;
  background: none;
  cursor: pointer;
}

.simple-table .add-button:hover {
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
}

.simple-table dialog {
  border: 1px solid #222;
  border-radius: 5px;
  padding: 5px;
  background-color: white;
}
