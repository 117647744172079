.table {
  font-family: sans-serif;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
  margin: 25px auto;
}

.table,
.table td,
.table th {
  border: solid 1px #ddd;
  border-collapse: collapse;
}

.table th {
  padding: 10px;
  font-weight: normal;
}

.table input {
  border: none;
  background: none;
  font-family: sans-serif;
  font-weight: normal;
}

.table tr:hover {
  background-color: #f4f4f4;
}
