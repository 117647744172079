.timeregistration2022-lightweight-activity2022 {
  color: red;
  display: flex;
  width: 100%;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.54);
  padding: 0;
  font-size: 1rem;
  font-family: 'Work Sans';
  font-weight: 400;
  line-height: 1;
  width: calc(100% + 8px);
  margin: -4px;
}

.timeregistration2022-lightweight-activity2022 > div {
  flex-grow: 0;
  max-width: 8.333333%;
  flex-basis: 8.333333%;
  margin: 0;
  box-sizing: border-box;
}

.timeregistration2022-lightweight-activity2022 > div:nth-child(1),
.timeregistration2022-lightweight-activity2022 > div:nth-child(3),
.timeregistration2022-lightweight-activity2022 > div:nth-child(4) {
  max-width: 16.666667%;
  flex-basis: 16.666667%;
}

.timeregistration2022-lightweight-activity2022 > div > p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-bottom: 1px solid;
  margin-left: 4px;
  margin-right: 4px;
  padding-bottom: 6px;
}
